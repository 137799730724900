import React from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  Buttons,
  DownArrow,
} from "./HeroElements";
import Video from "../../videos/video.mp4";
import Arrow from "../../images/down-arrow.svg";

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted playsInline src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>LA LUZ FOUNDATION</HeroH1>
        <HeroP>
          At the La Luz Foundation, we build relationships and challenge individuals to act on their faith and values to create healthier communities.
        </HeroP>
        <Buttons
          to="mission"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
        >
          <DownArrow src={Arrow} />
        </Buttons>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
