import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #0e0e0e;
`;

export const FooterWrap = styled.div`
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 0 auto;

  /* @media screen and (max-width: 820px) {
    flex-direction: column;
  } */
`;

export const SocialMediaLogoWrap = styled.div`
  max-width: 1100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 15px;

  /* @media screen and (max-width: 820px) {
    flex-direction: column;
  } */
`;

export const SocialLogo = styled.img`
  filter: brightness(0) invert(1);
  justify-content: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -50px;
  margin-bottom: -24px;
  height: 100px;

  @media screen and (max-width: 820px) {
    margin-left: 0;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;

  &:hover {
    color: ${(props) => props.bgColor};
  }
`;
