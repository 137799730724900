import styled from "styled-components";

export const MembersContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #121212;

  @media screen and (max-width: 1000px) {
    height: 1300px;
  }

  @media screen and (max-width: 768px) {
    height: 2000px;
  }
`;

export const MembersWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  align-items: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const MembersCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 240px;
  padding: 10px;
  margin: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
`;

export const MembersIcon = styled.img`
  height:200px;
  width: 140px;
  margin-bottom: 10px;
`;

export const MembersH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const MembersH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const MembersP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
