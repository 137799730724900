import React from "react";
import Manuel from "../../images/manuel.jpg";
import Edgar from "../../images/edgar.jpg";
import Carlos from "../../images/carlos.jpg";
import Luis from "../../images/luis.jpg";
import Bart from "../../images/bart.jpg";
import Jennifer from "../../images/jennifer.jpg";

import {
  MembersContainer,
  MembersH1,
  MembersWrapper,
  MembersCard,
  MembersIcon,
  MembersH2,
} from "./MembersElements";

const Members = () => {
  return (
    <MembersContainer id="ourMembers">
      <MembersH1>Our Members</MembersH1>
      <MembersWrapper>
        <MembersCard>
          <MembersIcon src={Manuel} />
          <MembersH2>Manuel Carrera</MembersH2>
        </MembersCard>
        <MembersCard>
          <MembersIcon src={Edgar} />
          <MembersH2>Edgar Montiel</MembersH2>
        </MembersCard>
        <MembersCard>
          <MembersIcon src={Carlos} />
          <MembersH2>Carlos Jaramillo</MembersH2>
        </MembersCard>
        <MembersCard>
          <MembersIcon src={Luis} />
          <MembersH2>Bart Zona</MembersH2>
        </MembersCard>
        <MembersCard>
          <MembersIcon src={Bart} />
          <MembersH2>Daniel Perez</MembersH2>
        </MembersCard>
        <MembersCard>
          <MembersIcon src={Jennifer} />
          <MembersH2>Daniel Perez</MembersH2>
        </MembersCard>
      </MembersWrapper>
    </MembersContainer>
  );
};

export default Members;
