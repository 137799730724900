import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#121212")};
  padding: 100px 0;
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 1700px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 1600px;
  }
`;

export const InfoRow = styled.div`
  grid-template-areas: "col1 col1" "col2 col2";
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 600px;
`;

export const TopLine = styled.h1`
  color: ${({ darkText }) => (darkText ? "#121212" : "#fff")};
  font-size: 48px;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 40px;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 600px;
  font-size: 18px;
  line-height: 30px;
  color: ${({ darkText }) => (darkText ? "#121212" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  max-width: 600px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
`;

export const ListWrapper = styled.ul`
  color: ${({ darkText }) => (darkText ? "#121212" : "#fff")};
  padding-top: 20px;
  padding-left: 50px;
`;

export const List = styled.li`
padding-bottom: 15px;
`;
