export const homeObjOne = {
  id: "mission",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Mission",
  description:
    "The La Luz Foundation (LLF) inspires, encourages, and supports Morton College by providing scholarships to current and prospective Morton College students who otherwise would not have the financial means to fulfill their educational goals.",
  buttonLabel: "Get started",
  img: require("../../images/mission.jpg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};