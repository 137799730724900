export const aboutUsObjOne = {
  id: "aboutUs",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About Us",
  description:
    "LLF sees higher education as an agent of change for individuals, families, and communities. We envision a community that recognizes the importance of educational attainment and assures positive educational outcomes are accessible to all regardless of economic circumstance.",
  listHeader: "To build this community, LLF will:",
  list: [
    "Promote and provide financial support for postsecondary education",
    "Advise students and their families on how to save for and afford college",
    "Target underserved and often overlooked students, but not limited to the Morton College district",
  ],
  description2:
    "In addition, LLF has been formed for the purpose of performing all things incidental to, or appropriate in, the foregoing specific and primary purposes. However, the LLF shall not, except to an insubstantial degree, engage in any activity or exercise of any powers which are not in the furtherance of its primary non-profit purposes.",
  img: require("../../images/aboutus1.jpg").default,
  img2: require("../../images/aboutus2.jpg").default,
  dark: false,
  primary: false,
  darkText: true,
};
