import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  AppSidebarLink,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="mission" onClick={toggle}>
            Mission
          </SidebarLink>
          <SidebarLink to="aboutUs" onClick={toggle}>
            About Us
          </SidebarLink>
          <SidebarLink to="ourMembers" onClick={toggle}>
            Our Members
          </SidebarLink>
          <AppSidebarLink
            href="https://forms.gle/cuL6wrWFLHjLyzdWA"
            target="_blank"
            onClick={toggle}
          >
            Apply Today
          </AppSidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_blank"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="2BG5PTBDFTZ3G"
            />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_US/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
