import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  SocialMediaLogoWrap,
} from "./FooterElements";
import Logo from "../../images/logo.png";
import {
  FaFacebook,
  FaInstagram,
  // FaYoutube,
  // FaTwitter,
  // FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo src={Logo} onClick={toggleHome} />
            <SocialMediaLogoWrap>
              <SocialIconLink
                bgColor={"#4267B2"}
                href="https://www.facebook.com/LaLuzFoundation/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                bgColor={"#E1306C"}
                href="https://www.instagram.com/laluzfoundation/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              {/* 
              <SocialIconLink
                bgColor={"#FF0000"}
                href="/"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                bgColor={"#1DA1F2"}
                href="/"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                bgColor={"#0077B5"}
                href="/"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink> */}
                </SocialMediaLogoWrap>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
