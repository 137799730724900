import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Subtitle,
  Column2,
  ImgWrap,
  Img,
  ListWrapper,
  List,
} from "./AboutUsElements";

import aboutus1 from "../../images/aboutus1.jpg";
import aboutus2 from "../../images/aboutus2.jpg";

const AboutUsSection = ({
  lightBg,
  id,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  listHeader,
  list,
  description2,
  buttonLabel,
  img,
  img2,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine darkText={darkText}>{topLine}</TopLine>
                <Subtitle darkText={darkText}>{description}</Subtitle>
              </TextWrapper>
              <ImgWrap>
                <Img src={aboutus1} alt={alt} />
              </ImgWrap>
            </Column1>
            <Column2>
              <TextWrapper>
                <Subtitle darkText={darkText}>{listHeader}</Subtitle>
                <ListWrapper darkText={darkText}>
                  <List>{list[0]}</List>
                  <List>{list[1]}</List>
                  <List>{list[2]}</List>
                </ListWrapper>
              </TextWrapper>
              <ImgWrap>
                <Img src={aboutus2} alt={alt} />
              </ImgWrap>
              <TextWrapper>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
              </TextWrapper>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default AboutUsSection;
